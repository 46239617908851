export const formatNumber = (value: number | string) => {
  if (`${value}`.length === 4 && Number(value) > 0) {
    const case4str1 = `${value}`.slice(0, 1);
    const case4str2 = `${value}`.slice(1);
    return case4str1.concat(",", case4str2);
  }
  if (`${value}`.length === 5) {
    const case5str1 = `${value}`.slice(0, 2);
    const case5str2 = `${value}`.slice(2);
    return case5str1.concat(",", case5str2);
  }
  if (`${value}`.length === 6) {
    const case6str1 = `${value}`.slice(0, 3);
    const case6str2 = `${value}`.slice(3);
    return case6str1.concat(",", case6str2);
  }
  if (`${value}`.length === 7 && Number(value) < 0) {
    const case7str1 = `${value}`.slice(0, 4);
    const case7str2 = `${value}`.slice(4);
    return case7str1.concat(",", case7str2);
  }
  if (`${value}`.length === 7 && Number(value) > 0) {
    const case7str1 = `${value}`.slice(0, 1);
    const case7str2 = `${value}`.slice(1, 3);
    const case7str3 = `${value}`.slice(3);
    return case7str1.concat(",", case7str2).concat("", case7str3);
  }
  return value;
};

export const formatNumberType = (value: number | null | undefined) => {
  if (Number.isInteger(value) || value === null) {
    return Math.round(Number(value));
  } else {
    return Number(value).toFixed(2);
  }
};
