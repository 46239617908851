import { FC, useEffect } from "react";
import { Box, TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReportFiltersButton } from "components/buttons/ReportFiltersButton";
import { useDispatch } from "react-redux";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useTableFilters } from "hooks/useTableFilters";
import {
  DASChangeRowsPerPage,
  getReportsAction,
  onCreateReportAction,
  setReportTablePagginationPage,
} from "actions/daDailyReportsAction";
import { localUserService } from "utils/localStorage";
import { ModalWindow } from "components/templateComponents/modalWindow";
import {
  ReportCreateModalContent,
  ReportCreateParams,
} from "components/modals/modalContent/ReportCreateModal";
import { useNav } from "hooks/useNav";
import { AccessToCreateOldShiftsModalContent } from "components/modals/modalContent/AccessToCreateOldShiftsModal";

interface ReportsBudgetaHeadProps {}

const SORT_OPTIONS = ["report_date", "created", "modified"];

export const ReportsListFilter: FC<ReportsBudgetaHeadProps> = () => {
  const classes = useStyles();
  const { viewReport } = useNav();
  const dispatch = useDispatch();
  const permission = localUserService.getPermissions();
  const { reportListCount, reportListFilters, dasBoardRowsPerPage } = useTypeSelectors(
    (state) => state.dasBoard,
  );

  const handleChangeRowsPerPage = (data: number) => {
    dispatch(DASChangeRowsPerPage(data));
    dispatch(setReportTablePagginationPage(0));
  };

  const handleChangePagination = (event: unknown, value: number) => {
    dispatch(setReportTablePagginationPage(value));
  };

  const {
    pageNumber,
    dateCreate,
    userCreate,
    userEdit,
    shiftDate,
    dateFrom,
    dateTo,
    stationFilter,
    dateMonth,
    dateYear,
    driver,
    week_number,
    missing,
    cod,
    country,
    fleet_number,
    fleet_group,
    fleet_size,
  } = reportListFilters;

  const { rowsPerPage, setRowsPerPage, sort } = useTableFilters(
    "report_date",
    SORT_OPTIONS,
    dasBoardRowsPerPage,
    handleChangeRowsPerPage,
  );

  useEffect(() => {
    const year = dateMonth ? +dateMonth.slice(0, 4) : undefined;
    const month = dateMonth ? +dateMonth.slice(5) : undefined;
    const dateYearCondition = dateYear ? Number(dateYear) : undefined;
    const yearQuery = year ? year : dateYearCondition;

    dispatch(
      getReportsAction(
        pageNumber + 1,
        rowsPerPage,
        sort,
        dateCreate,
        userCreate,
        userEdit,
        shiftDate,
        yearQuery,
        month,
        dateFrom,
        dateTo,
        stationFilter,
        driver,
        week_number,
        missing,
        cod,
        country,
        fleet_number,
        fleet_group,
        fleet_size,
      ),
    );
  }, [
    rowsPerPage,
    pageNumber,
    sort,
    dispatch,
    userCreate,
    userEdit,
    dateCreate,
    shiftDate,
    stationFilter,
    dateFrom,
    dateTo,
    dateMonth,
    dateYear,
    driver,
    week_number,
    missing,
    cod,
    country,
    fleet_number,
    fleet_group,
    fleet_size,
  ]);

  const onCreateReport = (data: ReportCreateParams) => {
    dispatch(onCreateReportAction(data, viewReport));
  };

  return (
    <>
      <Box className={classes.buttonBox}>
        <ModalWindow modal_name={"create-new-report-modal"} modalTitle={"Create New Report"}>
          <ReportCreateModalContent onSubmit={onCreateReport} />
        </ModalWindow>
        {permission === 4 && (
          <ModalWindow
            modal_name={"access-to-create-old-shifts-modal"}
            buttonName={"Access to create old shifts"}
            modalTitle={"Set Access To Create Old Shifts"}
          >
            <AccessToCreateOldShiftsModalContent />
          </ModalWindow>
        )}
        <ReportFiltersButton />
      </Box>
      <TablePagination
        page={pageNumber}
        rowsPerPage={rowsPerPage}
        count={reportListCount}
        onRowsPerPageChange={setRowsPerPage}
        onPageChange={handleChangePagination}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  filter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
    "@media (max-width: 1100px)": {
      padding: theme.spacing(3, 1, 1),
      gap: 10,
      flexDirection: "column",
    },
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "flex-start",
    gap: "10px",
    "@media (max-width: 1100px)": {
      flexDirection: "column",
    },
  },
  typeButtons: {
    display: "flex",
  },
}));
