import { Box, Grid } from "@material-ui/core";
import { FC } from "react";
import { DAStatusGraph } from "./DashboardCharts/DAStatusGraph";
import { FleetStatusGraph } from "./DashboardCharts/FleetStatusGraph";

interface DashboardStatusesType {}

export const DashboardStatuses: FC<DashboardStatusesType> = () => {
  return (
    <Box data-testid="testDashboardScores">
      <Grid container spacing={1}>
        <Grid item sm={12} md={8} lg={12}>
          <Grid container spacing={2} style={{ height: "100%" }}>
              <Grid item sm={12} md={12} lg={6}>
                <FleetStatusGraph />
              </Grid>
              <Grid item sm={6} md={12} lg={6}>
                <DAStatusGraph />
              </Grid>
          </Grid>
      </Grid>
    </Grid>
    </Box>
  );
};
