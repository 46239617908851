import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "10px",
    height: "80vh",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    boxSizing: "border-box",
    gap: "10px",
    justifyContent: "space-between",
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
  },
  graphPaper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    boxSizing: "border-box",
    gap: "10px",
    height: "100%",
    justifyContent: "space-between",
    boxShadow: "none",
    borderRadius: 15,
    background: "white",
    borderBottom: "#e8e9ee 2px solid",
  },
  performersChartBox: {
    width: "100%",
    height: `100%`,
    margin: "0 auto",
  },
  doughnutsTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  smallDoughnutRoot: { display: "flex", flexDirection: "column", gap: "10px" },
  smallDoughnutMainBox: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "space-evenly",
  },
  smallDoughnutsBox: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "flex-start",
  },
  smallDoughnutBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
  },
  smallDoughnutLabelsBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  },
  smallDoughnutInfoBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  bigDoughnutBox: {
    maxWidth: `160px`,
    maxHeight: `160px`,
    margin: "0",
    width: "100%",
  },
  smallDoughnutBox: {
    width: `100px`,
    height: `100px`,
    margin: "0 auto",
    position: "relative",
  },
  graphHead: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  graphHeadTitle: {
    color: Colors.BLUE
  },
  graphHeadIcon: {
    color: Colors.BLUE,
    width: 35,
    height: 35,
  }
}));
