import { FC, useState } from "react";
import {
  Drawer,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  FormControl,
  TextField,
  Box,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useDispatch } from "react-redux";
import { setFiltersAction, setFiltersTabAction } from "actions/daDailyReportsAction";
import { TabWrapper } from "components/elements/TabPanel";
import { AutoSelect } from "components/elements/AutoSelect";
import { YearSelect } from "components/elements/YearSelect";
import moment from "moment";
import api from "api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AutoComplete } from "components/elements/AutoComplete";
import { useDialogStyles } from "components/modals/styles";
import {  OnChangeEvent } from "types/general";

interface ReportFiltersModalProps {
  open: boolean;
  onClose: () => void;
  tabOption?: number;
}

const defaultYear = moment().format("YYYY");

const getUsersByCreatedOptions = () => api.getUsers({ by_create: true });
const getUsersByUpdatedOptions = () => api.getUsers({ by_update: true });
const getDriverList = () => api.getDriverList();
const getCountries = () => api.getCountries();
const getVans = () => api.getVans();
const getVanGroups = () => api.getVanGroups();
const getSizes = () => api.getFleetSizes();

export const ReportFiltersDrawer: FC<ReportFiltersModalProps> = ({ open, onClose, tabOption }) => {
  const dispatch = useDispatch();
  const dialogClasses = useDialogStyles();
  const classes = useFiltersStyles();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { reportListFilters, reportListFiltersTab } = useTypeSelectors((state) => state.dasBoard);

  const [userCreate, setUserCreate] = useState<number | null>(
    reportListFilters?.userCreate || null
  );
  const [userEdit, setUserEdit] = useState<number | null>(reportListFilters?.userEdit || null);
  const [driver, setDriver] = useState<number | null>(reportListFilters?.driver || null);
  const [dateCreate, setDateCreate] = useState<string>(reportListFilters?.dateCreate || "");
  const [shiftDate, setShiftDate] = useState<string>(reportListFilters?.shiftDate || "");
  const [stationFilter, setStationFIlter] = useState<number | null>(
    reportListFilters?.stationFilter || null
  );
  const [missing, setMissing] = useState<boolean>(reportListFilters?.missing || false);
  const [cod, setCod] = useState<boolean>(reportListFilters?.cod || false);
  const [dateFrom, setDateFrom] = useState<string>(reportListFilters?.dateFrom || "");
  const [dateTo, setDateTo] = useState<string>(reportListFilters?.dateTo || "");
  const [dateMonth, setDateMonth] = useState<string>(reportListFilters?.dateMonth || "");
  const [dateYear, setDateYear] = useState<string>(reportListFilters?.dateYear || defaultYear);
  const [dateWeek, setDateWeek] = useState<string>(reportListFilters?.week_number || "");
  const [country, setCountry] = useState<number | null>(reportListFilters?.country || null);
  const [van, setVan] = useState<number | null>(reportListFilters?.fleet_number || null);
  const [vanGroup, setVanGroup] = useState<number | null>(reportListFilters?.fleet_group || null);
  const [fleetSize, setFleetSize] = useState<number | null>(reportListFilters?.fleet_size || null);

  const handleFilterReset = () => {
    dispatch(
      setFiltersAction({
        pageNumber: 0,
        userCreate: null,
        userEdit: null,
        dateCreate: "",
        shiftDate: "",
        stationFilter: null,
        dateFrom: "",
        dateTo: "",
        dateMonth: "",
        dateYear: "",
        driver: null,
        week_number: "",
        missing: false,
        cod: false,
        country: null,
        fleet_number: null,
        fleet_group: null,
        fleet_size: null,
      })
    );
    setUserCreate(null);
    setUserEdit(null);
    setDateCreate("");
    setShiftDate("");
    setStationFIlter(null);
    setDateFrom("");
    setDateTo("");
    setDateMonth("");
    setDateYear(defaultYear);
    setDriver(null);
    setDateWeek("");
    setMissing(false);
    setCod(false);
    setVan(null);
    setVanGroup(null);
    setFleetSize(null);
    onClose();
  };

  const handleFilterSubmit = () => {
    dispatch(
      setFiltersAction({
        pageNumber: 0,
        userCreate,
        userEdit,
        stationFilter,
        driver,
        ...(missing && { missing }),
        ...(cod && { cod }),
        ...(country && { country }),
        ...(van && { fleet_number: van }),
        ...(vanGroup && { fleet_group: vanGroup }),
        ...(fleetSize && { fleet_size: fleetSize }),
        ...(reportListFiltersTab === 0
          ? { shiftDate, dateCreate }
          : { shiftDate: "", dateCreate: "" }),
        ...(reportListFiltersTab === 1 ? { dateMonth } : { dateMonth: "" }),
        ...(reportListFiltersTab === 2 ? { dateYear } : { dateYear: "" }),
        ...(reportListFiltersTab === 3 ? { dateFrom, dateTo } : { dateFrom: "", dateTo: "" }),
        ...(reportListFiltersTab === 4 ? { week_number: dateWeek } : { week_number: "" }),
      })
    );
    onClose();
  };

  const handleChangeFilterTab = (value: number) => {
    dispatch(setFiltersTabAction(value));
  };

  return (
    <Drawer
      data-testid="reportFilterSelectDrawer"
      anchor="right"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { maxWidth: 500 } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4">Filter by:</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ width: "100%", maxWidth: "48%" }}>
              <FormControl fullWidth style={{ marginBottom: "8px" }}>
                <InputLabel>Select station</InputLabel>
                <Select
                  inputProps={{ "data-testid": "reportFilterSelectStation" }}
                  label="Select station"
                  name="station"
                  value={stationFilter}
                  onChange={(e: OnChangeEvent) => setStationFIlter(e.target.value as number)}
                  placeholder="Choose the Station"
                >
                  {userData.station_spots ? (
                    userData?.station_spots.map(
                      (el: { station: { title: string; id: number } }) => (
                        <MenuItem key={el.station.id} value={el.station.id}>
                          {el.station.title}
                        </MenuItem>
                      )
                    )
                  ) : (
                    <MenuItem key={0} value={0}></MenuItem>
                  )}
                </Select>
              </FormControl>
              <AutoSelect
                label="Select by user created"
                name="user_created"
                placeholder="Choose user"
                value={userCreate}
                onChange={setUserCreate}
                getOptions={getUsersByCreatedOptions}
                renderOption={(el) => `${el.first_name} ${el.last_name}`}
                className={classes.formControl}
              />
              <AutoSelect
                label="Select by user edited"
                name="user_edited"
                placeholder="Choose user"
                value={userEdit}
                onChange={setUserEdit}
                getOptions={getUsersByUpdatedOptions}
                renderOption={(el) => `${el.first_name} ${el.last_name}`}
                className={classes.formControl}
              />
              <AutoSelect
                label="Select driver"
                name="driver"
                placeholder="Choose driver"
                value={driver}
                onChange={setDriver}
                getOptions={getDriverList}
                renderOption={(el) => `${el.first_name} ${el.last_name}`}
                className={classes.formControl}
              />
            </Box>
            <Box style={{ width: "100%", maxWidth: "48%" }}>
            <AutoSelect
                label="Select country"
                name="country"
                placeholder="Choose country"
                value={country}
                onChange={setCountry}
                getOptions={getCountries}
                renderOption={(el) => el.name}
                className={classes.formControl}
              />
              <AutoComplete
                label="Select Fleet"
                placeholder="Choose Fleet"
                value={van}
                onChange={setVan}
                getOptions={getVans}
                renderOption={(el) => el.number}
                className={classes.formControl}
              />
              <AutoSelect
                label="Select Fleet Group"
                name="fleet_group"
                placeholder="Choose Fleet Group"
                value={vanGroup}
                onChange={setVanGroup}
                getOptions={getVanGroups}
                renderOption={(el) => el.name}
                className={classes.formControl}
              />
              <AutoSelect
                label="Select Fleet Size"
                name="fleet_size"
                placeholder="Choose Fleet Size"
                value={fleetSize}
                onChange={setFleetSize}
                getOptions={getSizes}
                renderOption={(el) => el.title}
                className={classes.formControl}
              />
            </Box>
          </Box>
          <Accordion className={`${classes.formControl} ${classes.moreOptions}`}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>More Options</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControlLabel
                onChange={() => setMissing(!missing)}
                checked={missing}
                control={<Checkbox />}
                label={<Typography style={{ marginRight: "10px" }}>By missing</Typography>}
              />
              <FormControlLabel
                onChange={() => setCod(!cod)}
                checked={cod}
                control={<Checkbox />}
                label={<Typography style={{ marginRight: "10px" }}>By COD</Typography>}
              />
            </AccordionDetails>
          </Accordion>
          <TabWrapper
            value={tabOption || reportListFiltersTab}
            onChange={handleChangeFilterTab}
            tabs={[
              {
                label: "Shift and create date",
                component: (
                  <Box>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        inputProps={{ "data-testid": "reportFilterShiftDate" }}
                        label="Filter by shift date"
                        name="shift_date"
                        type="date"
                        value={shiftDate}
                        onChange={(e: OnChangeEvent) => setShiftDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        inputProps={{ "data-testid": "reportFilterDateCreated" }}
                        label="Filter by date created"
                        name="created_date"
                        type="date"
                        value={dateCreate}
                        onChange={(e: OnChangeEvent) => setDateCreate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Box>
                ),
              },
              {
                label: "Month & year",
                component: (
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      inputProps={{ "data-testid": "reportFilterByMonthYear" }}
                      label="Filter by month and year"
                      name="date_month"
                      type="month"
                      value={dateMonth}
                      onChange={(e: OnChangeEvent) => setDateMonth(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                ),
              },
              {
                label: "year",
                component: (
                  <YearSelect
                    year={+dateYear}
                    onNext={() => setDateYear(String(+dateYear + 1))}
                    onPrev={() => setDateYear(String(+dateYear - 1))}
                  />
                ),
              },
              {
                label: "date range",
                component: (
                  <Box>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        inputProps={{ "data-testid": "reportFilterByShiftDateFrom" }}
                        label="Filter by Shift date From"
                        name="date_from"
                        type="date"
                        value={dateFrom}
                        onChange={(e: OnChangeEvent) => setDateFrom(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        inputProps={{ "data-testid": "reportFilterByShiftDateTo" }}
                        label="Filter by Shift date To"
                        name="date_to"
                        type="date"
                        value={dateTo}
                        onChange={(e: OnChangeEvent) => setDateTo(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Box>
                ),
              },

              {
                label: "Week",
                component: (
                  <Box>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        inputProps={{ "data-testid": "reportFilterByWeek" }}
                        label="Filter by Week"
                        name="date_week"
                        type="week"
                        value={dateWeek}
                        onChange={(e: OnChangeEvent) => setDateWeek(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Box>
                ),
              },
            ]}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ padding: "32px 24px", gap: "20px", }}
      >
        <Button
          className={dialogClasses.actionButton}
          onClick={onClose}
        >Cancel</Button>
        <Button
          className={dialogClasses.actionButton}
          onClick={handleFilterReset} color="secondary"
        >
          Reset
        </Button>
        <Button
          className={dialogClasses.actionButton}
          onClick={handleFilterSubmit}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Drawer>
  );
};

const useFiltersStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
  },
  moreOptions: {
    margin: theme.spacing(3, 0),
  }
}));
