import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  Drawer,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDialogStyles } from "components/modals/styles";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { DashboardSetStationsFilters } from "actions/dashboardActions";

interface DashboardStationFilterDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const DashboardStationFilterDrawer: FC<DashboardStationFilterDrawerProps> = ({
  open,
  onClose,
}) => {
  const { userData } = useTypeSelectors((state) => state.auth);
  const { dashboardScoreSettings } = useTypeSelectors((state) => state.dashboard);
  const [stationFilter, setStationFilter] = useState(dashboardScoreSettings?.currentStations);
  const dispatch = useDispatch();
  const dialogClasses = useDialogStyles();

  useEffect(() => {
    setStationFilter(dashboardScoreSettings?.currentStations);
  }, [dashboardScoreSettings?.currentStations]);

  const handleStationFilter = (station: number) => {
    if (stationFilter?.includes(station)) {
      setStationFilter(stationFilter?.filter((item: number) => item !== station));
    } else {
      setStationFilter([...stationFilter!, station]);
    }
  };

  const setAllStations = () => {
    if (stationFilter?.length === userData.station_spots.length) {
      setStationFilter([]);
    } else {
      setStationFilter(userData.station_spots.map((item) => item?.station.id));
    }
  };

  const handleFilterSubmit = () => {
    onClose();
    if (stationFilter?.length !== 0) {
      dispatch(DashboardSetStationsFilters(stationFilter!));
    } else {
      dispatch(DashboardSetStationsFilters([1]));
    }
  };

  const onRefresh = () => {
    setStationFilter([]);
  };

  return (
    <Drawer
      data-testid="testActFilterDrawer"
      anchor="right"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { maxWidth: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4">Select Stations:</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            minWidth: 250,
            display: "flex",
            gap: 10,
            flexDirection: "column",
          }}
        >
          <FormControlLabel
            onChange={setAllStations}
            checked={stationFilter?.length === userData.station_spots.length}
            control={<Checkbox />}
            label={<Typography style={{ marginRight: "10px" }}>All</Typography>}
          />
          {userData?.station_spots?.map((item) => (
            <FormControlLabel
              key={item?.station?.title}
              onChange={(e: any) => handleStationFilter(item?.station?.id)}
              checked={stationFilter?.includes(item?.station?.id)}
              control={<Checkbox />}
              label={
                <Typography style={{ marginRight: "10px" }}>{item?.station?.title}</Typography>
              }
            />
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "32px 24px", gap: "20px" }}>
        <Button className={dialogClasses.actionButton} onClick={onClose}>
          Cancel
        </Button>
        <Button className={dialogClasses.actionButton} onClick={onRefresh}>
          Refresh
        </Button>
        <Button
          className={dialogClasses.actionButton}
          onClick={handleFilterSubmit}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Drawer>
  );
};
