import { DashboardState } from "types/DashboardRecord";
import { DashboardConstants } from "../constants/constants";
import moment from "moment";
import { ReducersInterfaceAction } from "types/Reducers";
import { initialDashboardState } from "store/initialStates";

export const dashboardReducer = (
  state = initialDashboardState,
  action: ReducersInterfaceAction,
): DashboardState => {
  switch (action.type) {
    case DashboardConstants.DASHBOARD_DATE_LOADING:
      return {
        ...state,
        dashBoardDateLoading: true,
      };
    case DashboardConstants.DASHBOARD_DATE_SUCCESS:
      return {
        ...state,
        dashBoardDate: action.payload,
        dashBoardDateLoading: false,
      };
    case DashboardConstants.DASHBOARD_DATE_ERROR:
      return {
        ...state,
        dashBoardDateLoading: false,
      };
    case DashboardConstants.DASHBOARD_MODAL_STATE:
      return {
        ...state,
        dashboardModalState: !state.dashboardModalState,
      };
    case DashboardConstants.DASHBOARD_LOADING:
      return {
        ...state,
        dashboardDataLoading: true,
      };
    case DashboardConstants.DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardDataLoading: false,
        dashboardDataList: action.payload.results,
        dashboardDataListCount: action.payload.count,
      };
    case DashboardConstants.DASHBOARD_ERROR:
      return {
        ...state,
        dashboardDataLoading: false,
      };
    case DashboardConstants.DASHBOARD_UPDATE_SETTINGS:
      return {
        ...state,
        dashboardScoreSettings: { ...state.dashboardScoreSettings, ...action.payload },
      };
    case DashboardConstants.DASHBOARD_CHANGE_ROWS_PER_PAGE:
      return {
        ...state,
        dashboardTableRowsPerPage: action.payload,
      };
    case DashboardConstants.DASHBOARD_INITIALIZE_LABEL_DATE_TYPE:
      return {
        ...state,
        dashboardScoreSettings: {
          ...state.dashboardScoreSettings,
          labelDate: {
            week: moment().format("W"),
            month: moment().format("M"),
            year: moment().format("yyyy"),
            day: moment().format("DD-MM-yyyy"),
          },
        },
      };
    case DashboardConstants.DASHBOARD_UPDATE_STATIONS_FILTER:
      return {
        ...state,
        dashboardScoreSettings: {
          ...state.dashboardScoreSettings,
          currentStations: action.payload,
        },
      };
    default:
      return state;
  }
};
