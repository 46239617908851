import { FC, useState, useEffect } from "react";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import moment from "moment";
import { inputNumberValidateOnPaste } from "utils/formats";
import { useDispatch } from "react-redux";
import { closeModal } from "actions/modalActions";
import { useDialogStyles } from "../styles";
import { Colors } from "constants/constants";
import { OnChangeEvent } from "types/general";
import { DialogButton } from "components/buttons/DialogButton";

export type Station = {
  id: number;
  title: string;
};

export type ReportCreateParams = {
  station: number;
  date: string;
  rows: number;
};

interface ReportCreateModalContentProps {
  onSubmit: (data: ReportCreateParams) => void;
}

export const ReportCreateModalContent: FC<ReportCreateModalContentProps> = ({ onSubmit }) => {
  const classes = useDialogStyles();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { reportList } = useTypeSelectors((state) => state.dasBoard);
  const { isOpen } = useTypeSelectors((state) => state.modalState);
  const minReportDate = moment().format("yyyy-MM-DD");
  const defaultReportDate = moment().add(1, "days").format("yyyy-MM-DD");
  const conditionForCreateOldShifts = userData?.can_create_old_shifts ? null : minReportDate;

  const [station, setStation] = useState<number | null>(null);
  const [date, setDate] = useState<string>(defaultReportDate);
  const [rows, setRows] = useState(0);

  const dispatch = useDispatch();

  const conditionStation = (val: number) => {
    const report = reportList.some((e) => e.report_date === date && val === e.station.id);
    return report;
  };

  const onClose = () => dispatch(closeModal());

  const handleSubmit = () => {
    if (station && date) {
      onClose();
      onSubmit({ station, date, rows });
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setStation(null);
      setDate(defaultReportDate);
    }
  }, [isOpen, defaultReportDate]);

  return (
    <>
      <DialogContent data-testid="testReportCreateModal">
        <DialogContentText
          id="alert-dialog-description"
          style={{
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl style={{ marginBottom: 20 }}>
            <InputLabel>Select station</InputLabel>
            <Select
              inputProps={{ "data-testid": "reportCreateStationInput" }}
              label="Select station"
              name="station"
              value={station}
              onChange={(e: OnChangeEvent) => setStation(e.target.value as number)}
              placeholder="Choose the Station"
            >
              {userData.station_spots ? (
                userData?.station_spots?.map((el: { station: Station }) => (
                  <MenuItem
                    key={el.station.id}
                    value={el.station.id}
                    disabled={conditionStation(el.station.id)}
                  >
                    {el.station.title}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={0} value={0}></MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl style={{ marginBottom: 20 }}>
            <TextField
              label="Select date"
              name="report_date"
              type="date"
              value={date}
              onChange={(e: OnChangeEvent) => setDate(e.target.value)}
              InputProps={{
                inputProps: {
                  min: conditionForCreateOldShifts,
                  "data-testid": "reportCreateDateInput",
                },
              }}
            />
          </FormControl>
          <FormControl style={{ marginBottom: 20 }}>
            <TextField
              label="Select rows"
              name="rows"
              type="number"
              value={rows}
              onChange={(e: OnChangeEvent) => setRows(Number(e.target.value))}
              onPaste={inputNumberValidateOnPaste}
              InputProps={{
                inputProps: {
                  min: 0,
                  "data-testid": "reportCreateRowsInput",
                },
              }}
            />
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions data-testid="testReportCreateModalActions" className={classes.dialogActions}>
        <DialogButton
          testId={"cancelReportCreateButton"}
          buttonName={"Cancel"}
          onClick={onClose}
          customStyle={{ background: Colors.GRAY }}
        />
        <DialogButton
          testId={"createReportCreateButton"}
          buttonName={"Create"}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!(station && date)}
        />
      </DialogActions>
    </>
  );
};
