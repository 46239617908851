import api, { apiErrorHandler } from "api";
import { Dispatch } from "redux";
import { DasBoardConstants } from "constants/constants";
import { customToast } from "hooks/toast";
import { RootState } from "reducers/rootReducer";
import { ReportFiltersParams, ReportRecord } from "types/Report";

export const getReportsAction =
  (
    page: number,
    rows: number,
    order: string,
    created_date?: string | "",
    created_by?: number | null,
    edited_by?: number | null,
    date_shift?: string | "",
    year?: number | undefined,
    month?: number | undefined,
    date_from?: string | "",
    date_to?: string | "",
    station?: number | null,
    driver?: number | null,
    week_number?: string | null,
    missing?: boolean,
    cod?: boolean,
    country?: number | null,
    fleet_number?: number | null,
    fleet_group?: number | null,
    fleet_size?: number | null,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: DasBoardConstants.DAS_DAILY_REPORTS_PENDING });
    api
      .getReports({
        page: page,
        page_size: rows,
        ordering: order,
        created_date: created_date || undefined,
        created_by: created_by || undefined,
        edited_by: edited_by || undefined,
        date_shift: date_shift || undefined,
        year: year || undefined,
        month: month || undefined,
        date_from: date_from || undefined,
        date_to: date_to || undefined,
        station: station || undefined,
        driver: driver || undefined,
        week_number: week_number || undefined,
        ...(missing && { missing }),
        ...(cod && { cod }),
        ...(country && { country }),
        ...(fleet_number && { fleet_number }),
        ...(fleet_group && { fleet_group }),
        ...(fleet_size && { fleet_size }),
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: DasBoardConstants.DAS_DAILY_REPORTS_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch(
        apiErrorHandler((error) => {
          dispatch({ type: DasBoardConstants.DAS_DAILY_REPORTS_ERROR });
        }),
      );
  };

export const onCreateReportAction =
  (
    data: {
      station: number;
      date: string;
      rows: number;
    },
    onSuccess: (id: number) => void,
    onError?: () => void,
  ) =>
  (dispatch: Dispatch) => {
    dispatch({ type: DasBoardConstants.DAS_DAILY_REPORT_CREATE_PENDING });
    api
      .createReport({
        station: data.station,
        report_date: data.date,
        ...(data.rows ? { rows: data.rows } : null),
      })
      .then((res) => {
        if (res.data) {
          customToast("success", "DA report was created!");
          dispatch({
            type: DasBoardConstants.DAS_DAILY_REPORT_CREATE_SUCCESS,
            payload: res.data,
          });
          onSuccess(res.data.id);
        }
      })
      .catch(
        apiErrorHandler(() => {
          customToast("error", "The date has expired, please re-create the report!");
          dispatch({ type: DasBoardConstants.DAS_DAILY_REPORT_CREATE_ERROR });
          if (onError) onError();
        }),
      );
  };

export const getReportAction =
  (reportId: number, withLoading = true) =>
  async (dispatch: Dispatch) => {
    if (reportId) {
      if (withLoading) dispatch({ type: DasBoardConstants.DAS_DAILY_REPORT_GET_PENDING });
      api
        .getReport(reportId)
        .then((res) => {
          if (res.data) {
            dispatch({
              type: DasBoardConstants.DAS_DAILY_REPORT_GET_SUCCESS,
              payload: res.data,
            });
          }
        })
        .catch(
          apiErrorHandler(() => {
            dispatch({ type: DasBoardConstants.DAS_DAILY_REPORT_GET_ERROR });
          }),
        );
    }
  };

export const removeReportAction =
  (id: number) => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { reportList } = getStore()?.dasBoard;
    const updatedData = reportList.filter((el) => el.id !== id);
    dispatch({ type: DasBoardConstants.DAS_DAILY_REPORT_DELETE_PENDING });
    api
      .deleteReport(id)
      .then((res) => {
        dispatch({
          type: DasBoardConstants.DAS_DAILY_REPORT_DELETE_SUCCESS,
          payload: updatedData,
        });
      })
      .catch(
        apiErrorHandler(() => {
          dispatch({ type: DasBoardConstants.DAS_DAILY_REPORT_DELETE_ERROR });
        }),
      );
  };

export const handleUpdatePlan = (id: number, data: any) => async (dispatch: Dispatch) => {
  dispatch({ type: DasBoardConstants.DAS_DAILY_REPORT_PLAN_UPDATE_PENDING });

  api
    .updateReport(id, data)
    .then((res) => {
      dispatch({
        type: DasBoardConstants.DAS_DAILY_REPORT_PLAN_UPDATE_SUCCESS,
        payload: data,
      });
    })
    .catch(
      apiErrorHandler(() => {
        dispatch({ type: DasBoardConstants.DAS_DAILY_REPORT_PLAN_UPDATE_ERROR });
      }),
    );
};

export const createReportRecordAction =
  (recordId: number) => (dispatch: Dispatch, getStore: () => RootState) => {
    const { currentReport } = getStore()?.dasBoard;
    if (recordId) {
      api
        .createReportRecord(recordId)
        .then((res) => {
          dispatch({
            type: DasBoardConstants.DAS_DAILY_ADD_REPORT_FIELD,
            payload: [...currentReport, { ...res.data }],
          });
        })
        .catch(
          apiErrorHandler(() => {
            dispatch({ type: DasBoardConstants.DAS_DAILY_REPORT_GET_ERROR });
          }),
        );
    }
  };

export const updateReportRecordAction =
  (recordId: number, data: ReportRecord) => (dispatch: Dispatch, getStore: () => RootState) => {
    const { currentReport } = getStore()?.dasBoard;
    const updatedData = currentReport.map((el) => (el.id === recordId ? { ...el, ...data } : el));
    dispatch({
      type: DasBoardConstants.DAS_DAILY_UPDATE_REPORT_FIELD,
      payload: updatedData,
    });
  };

export const removeReportRecordAction =
  (id: number) => (dispatch: Dispatch, getStore: () => RootState) => {
    const { currentReport } = getStore()?.dasBoard;
    api
      .deleteReportRecord(id)
      .then(() => {
        const newData = currentReport.filter((el) => el.id !== id);
        dispatch({
          type: DasBoardConstants.DAS_DAILY_DELETE_REPORT_FIELD,
          payload: [...newData],
        });
      })
      .catch(
        apiErrorHandler(() => {
          dispatch({ type: DasBoardConstants.DAS_DAILY_REPORT_GET_ERROR });
        }),
      );
  };

export const notifyReportDriversAction = (id: number) => async (dispatch: Dispatch) => {
  api
    .sendMessageDrivers({ da_tracker_id: id })
    .then(() => {
      customToast("success", "Message to drivers has been send!");
      dispatch({ type: DasBoardConstants.DAS_DAILY_DRIVERS_NOTIFY_SUCCESS });
    })
    .catch(
      apiErrorHandler(() => {
        customToast("error", "Something was wrong!");
        dispatch({ type: DasBoardConstants.DAS_DAILY_DRIVERS_NOTIFY_ERROR });
      }),
    );
};

export const setFiltersAction = (data: ReportFiltersParams) => (dispatch: Dispatch) => {
  dispatch({
    type: DasBoardConstants.DAS_DAILY_REPORTS_FILTERS_SET,
    payload: data,
  });
};

export const setReportTablePagginationPage = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: DasBoardConstants.DAS_DAILY_REPORTS_FILTERS_SET,
    payload: { pageNumber: data },
  });
};

export const resetFiltersAction = () => (dispatch: Dispatch) => {
  dispatch({
    type: DasBoardConstants.DAS_DAILY_REPORTS_FILTERS_RESET,
  });
};

export const setFiltersTabAction = (value: number) => (dispatch: Dispatch) => {
  dispatch({
    type: DasBoardConstants.DAS_DAILY_REPORTS_FILTERS_TAB_SET,
    payload: value,
  });
};

export const DASChangeRowsPerPage = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: DasBoardConstants.DAS_TABLE_ROWS_PER_PAGE,
    payload: data,
  });
};

export const uploadTotalDeliveriesFile = (file: File) => async (dispatch: Dispatch) => {
  const onError = () => {
    dispatch({ type: DasBoardConstants.DAS_UPLOAD_FILE_INFO_ERROR });
    customToast("error", "Something was wrong!");
  };

  dispatch({ type: DasBoardConstants.DAS_UPLOAD_FILE_INFO_PENDING });

  const request: FormData = new FormData();
  request.append("file", file);

  api
    .uploadTotalDeliveriesFile(request)
    .then((res) => {
      if (res?.data) {
        dispatch({
          type: DasBoardConstants.DAS_UPLOAD_FILE_INFO_SUCCESS,
        });
        customToast("success", "Csv files was uploaded!");
      }
    })
    .catch(apiErrorHandler(onError));
};

export const changeDailyDAListType = (type: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: DasBoardConstants.CHANGE_PAGE_LIST_TYPE,
    payload: type,
  });
};
