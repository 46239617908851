import { FC, useState } from "react";
import { Badge, ButtonProps } from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useStyles } from "./styles";
import { OpenButton } from "./Buttons";
import { DashboardStationFilterDrawer } from "components/drawers/DashboardStationFilterDrawer";

interface DashboardDrawerButtonProps extends ButtonProps {}

export const DashboardDrawerButton: FC<DashboardDrawerButtonProps> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const { dashboardScoreSettings } = useTypeSelectors((state) => state.dashboard);

  return (
    <>
      <Badge
        className={classes.badge}
        badgeContent={dashboardScoreSettings?.currentStations?.length}
        color="error"
      >
        <OpenButton
          buttonText={"Station Filter"}
          variant={"outlined"}
          className={classes.buttonClass}
          color={"primary"}
          onClick={onOpen}
          props={props}
        />
      </Badge>
      <DashboardStationFilterDrawer open={open} onClose={onClose} />
    </>
  );
};
