import { FC, useState, useEffect } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { PdfGenerationModal } from "components/modals/PdfGenerationModal";
import { SearchInput } from "components/elements/SearchInput";
import { InfoBox } from "components/elements/InfoBox";
import { useNav } from "hooks/useNav";
import { useDispatch } from "react-redux";
import { createReportRecordAction } from "actions/daDailyReportsAction";
import { getTimeSincePost } from "utils/filters";
import { ModalWindow } from "components/templateComponents/modalWindow";
import { UploadExeShiftDataDialog } from "components/modals/modalContent/UploadExeShiftDataDialog";

interface ReportFilterProps {
  currentReportId: number;
  searchValue: string;
  onSearchValueChange: (value: string) => void;
}

export const ReportFilter: FC<ReportFilterProps> = ({
  currentReportId,
  searchValue,
  onSearchValueChange,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { currentReportStation, currentReportDate, currentReportDispatcher } = useTypeSelectors(
    (state) => state.dasBoard,
  );
  const [disabledByDate, setDisabledByDate] = useState(getTimeSincePost(currentReportDate));
  const conditionForDisabledEdit = userData?.can_create_old_shifts ? false : disabledByDate;
  const { viewReports } = useNav();

  const onAddNewField = () => dispatch(createReportRecordAction(currentReportId));

  useEffect(() => {
    if (currentReportDate) {
      setDisabledByDate(getTimeSincePost(currentReportDate));
    }
  }, [currentReportDate]);

  return (
    <Paper className={classes.filter}>
      <Grid container spacing={2}>
        <Grid item md={12} lg={5} style={{ display: "flex" }}>
          <Button
            fullWidth
            variant="contained"
            color="inherit"
            className={classes.button}
            onClick={viewReports}
          >
            Back to Reports
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onAddNewField}
            disabled={conditionForDisabledEdit}
          >
            Add New Record
          </Button>
          <PdfGenerationModal date={currentReportDate} station={currentReportStation?.title} />
          <ModalWindow
            fullWidth
            modal_name={"upload-xlsx-shift-data-model"}
            modalTitle={"Upload Report (.xlsx)"}
            buttonName={"Upload Report"}
            modalDescription={
              "Attention! After uploading files, please waiting to processing data. It should be around 15 sec. After this stage, reload the page to see the updated rows."
            }
            isDisabled={conditionForDisabledEdit}
          >
            <UploadExeShiftDataDialog />
          </ModalWindow>
        </Grid>
        <Grid item sm={12} md={6} lg={2} style={{ display: "flex", alignItems: "center" }}>
          <SearchInput value={searchValue} onChange={onSearchValueChange} />
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          lg={5}
          style={{ display: "flex", alignSelf: "center", justifyContent: "flex-end" }}
        >
          <InfoBox label={"Date"} value={currentReportDate} horizontal />
          <InfoBox label={"Station"} value={currentReportStation?.title} horizontal />
          <InfoBox
            label={"Dispatcher"}
            value={`${currentReportDispatcher?.first_name} ${currentReportDispatcher?.last_name}`}
            horizontal
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  filter: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    flexWrap: "wrap",
    borderRadius: 15,
    boxShadow: "none",
    borderBottom: "#e8e9ee 2px solid",
  },
  button: {
    marginRight: theme.spacing(1),
    textTransform: "none",
  },
  select: {
    padding: 0,
    marginRight: theme.spacing(1),
    minWidth: "150px",
  },
  textField: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));
