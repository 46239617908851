import {
  TableCell,
  TableRow,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import Alert, { Color } from "@material-ui/lab/Alert";
import { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { UserDataShort } from "types/User";
import { actStatusConstants, actActionConstants } from "constants/constants";
import { ActData, UpdateActTableTypes } from "types/Act";
import { IkeaActData, UpdateIkeaActTableTypes } from "types/IkeaAct";
import { OnBlurEvent, OnChangeEvent } from "types/general";

interface ActPageRow {
  actData: ActData | IkeaActData;
  superUserList: UserDataShort[];
  updateActTableInfo: (id: number, data: UpdateActTableTypes | UpdateIkeaActTableTypes) => void;
}

export const ActTableRow: FC<ActPageRow> = ({ actData, superUserList, updateActTableInfo }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [actionValue, setActionValue] = useState<number | null>(actData.action);
  const [statusValue, setStatusValue] = useState<number | null>(actData.status);
  const [paramsWeek, setParamsWeek] = useState({
    alertClassName: classes.redAlert,
    className: classes.redCell,
    severity: "error",
  });

  useEffect(() => {
    switch (statusValue) {
      case 1:
        setParamsWeek({
          alertClassName: classes.greenAlert,
          className: classes.greenCell,
          severity: "success",
        });
        break;
      case 2:
        setParamsWeek({
          alertClassName: classes.orangeAlert,
          className: classes.yellowCell,
          severity: "warning",
        });
        break;
      default:
        setParamsWeek({
          alertClassName: classes.redAlert,
          className: classes.redCell,
          severity: "error",
        });
    }
  }, [
    statusValue,
    classes.greenAlert,
    classes.greenCell,
    classes.orangeAlert,
    classes.redAlert,
    classes.redCell,
    classes.yellowCell,
  ]);

  return (
    <TableRow data-testid="testActRow" hover>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{`W${`${+actData?.get_week_number + 1}`.padStart(2, "0")}`}</Typography>
      </TableCell>
      <TableCell style={{ whiteSpace: "nowrap" }} className={`${classes.tableCell}`}>
        <Typography>{actData?.get_week_day.substring(0, 3)}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{actData?.off_target_area}</Typography>
      </TableCell>

      <TableCell style={{ minWidth: 60 }} className={`${classes.tableCell}`}>
        <FormControl style={{ display: "flex", alignContent: "center" }} fullWidth>
          <Select
            inputProps={{ "data-testid": "actRowActionInput" }}
            onChange={(e: OnChangeEvent) => {
              setActionValue(e?.target?.value);
              dispatch(
                updateActTableInfo(actData.id, {
                  action: e?.target?.value,
                }),
              );
            }}
            name="action"
            value={actionValue}
            defaultValue={actionValue}
            placeholder="action"
          >
            {actActionConstants?.map(({ value, option }: { value: number; option: string }) => (
              <MenuItem key={`action${option}`} value={value}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>

      <TableCell className={`${classes.tableCell}`}>
        <Typography>{actData?.driver_fullname}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{actData?.transporter_id}</Typography>
      </TableCell>

      <TableCell style={{ minWidth: 130 }} className={`${classes.tableCell}`}>
        <FormControl style={{ display: "flex", alignContent: "center" }} fullWidth>
          <Select
            inputProps={{ "data-testid": "actRowInputTwo" }}
            onChange={(e: OnChangeEvent) => {
              dispatch(
                updateActTableInfo(actData.id, {
                  owner: e?.target?.value,
                }),
              );
            }}
            name="owner"
            defaultValue={actData?.owner}
          >
            {superUserList?.map((item: { id: number; first_name: string; last_name: string }) => (
              <MenuItem
                key={`owner-${item.id}`}
                value={item.id}
              >{`${item?.first_name} ${item?.last_name}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>

      <TableCell style={{ minWidth: 130 }} className={`${classes.tableCell}`}>
        <FormControl style={{ display: "flex", alignContent: "center" }} fullWidth>
          <TextField
            inputProps={{ "data-testid": "actRowShiftDate" }}
            name="shift_date"
            type="date"
            defaultValue={actData?.due_date}
            InputLabelProps={{ shrink: true }}
            onChange={(e: OnChangeEvent) => {
              dispatch(
                updateActTableInfo(actData.id, {
                  due_date: e?.target?.value,
                }),
              );
            }}
          />
        </FormControl>
      </TableCell>

      <TableCell style={{ minWidth: 60 }} className={`${classes.tableCell}`}>
        <Alert
          icon={false}
          severity={paramsWeek.severity as Color}
          className={paramsWeek.alertClassName}
          style={{
            padding: "0 5px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormControl style={{ display: "flex", alignContent: "center" }} fullWidth>
            <Select
              inputProps={{ "data-testid": "actRowStatusInput" }}
              className={paramsWeek.className}
              onChange={(e: OnChangeEvent) => {
                setStatusValue(e?.target?.value);
                dispatch(
                  updateActTableInfo(actData.id, {
                    status: e?.target?.value,
                  }),
                );
              }}
              name="status"
              value={statusValue}
              defaultValue={statusValue}
              placeholder="status"
            >
              {actStatusConstants?.map(({ value, option }: { value: number; option: string }) => (
                <MenuItem key={`action${option}`} value={value}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Alert>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{actData?.coaching_count}</Typography>
      </TableCell>
      <TableCell style={{ minWidth: 150 }} className={`${classes.tableCell}`}>
        <TextField
          fullWidth
          defaultValue={actData?.comments}
          name={`comments`}
          placeholder={"..."}
          onBlur={(e: OnBlurEvent) => {
            dispatch(
              updateActTableInfo(actData.id, {
                comments: e?.target?.value,
              }),
            );
          }}
          multiline
          minRows={2}
          maxRows={4}
          inputProps={{
            style: { textAlign: "center", padding: "0 0 1px 0" },
            "data-testid": "actRowComments",
          }}
        />
      </TableCell>
    </TableRow>
  );
};
