import Boxes from "@material-ui/icons/Widgets";
import Truck from "@material-ui/icons/LocalShipping";
import ReplayIcon from "@material-ui/icons/Replay";
import NotInterested from "@material-ui/icons/NotInterested";

interface RoutesPlanDataType {
  completion: number,
  total_routes: number,
  total_plans: number,
  total_budgets: number
}

interface DeliveriesPlanDataType {
  from_actual: number,
  total_returns: number,
  total_plans: number,
  from_plan: number,
  returns: {
    actual:{
      missing?: number,
    }
    prev:{
      missing?: number,
    }
  }
}

interface useDashboardTrendsType {
  routesPlanData: RoutesPlanDataType | null;
  deliveriesPlanData: DeliveriesPlanDataType | null;
}

export const useDashboardTrends = ({
  routesPlanData,
  deliveriesPlanData,
}: any) => {
  const mockedInfoData: any = [
    {
      title: "# of Routes",
      type: "routes",
      Icon: Truck,
      totalScore: routesPlanData?.total_routes,
      totalPlans: routesPlanData?.total_plans,
      totalBudgets: routesPlanData?.total_budgets,
      completion: routesPlanData?.completion,
    },
    {
      title: "# of Deliveries",
      type: "deliveries",
      Icon: Boxes,
      totalScore: deliveriesPlanData?.total_deliveries,
      totalPlans: deliveriesPlanData?.total_plans,
      totalBudgets: deliveriesPlanData?.total_budgets,
      completion: deliveriesPlanData?.completion,
    },
    {
      title: "# of Returns",
      type: "returns",
      Icon: ReplayIcon,
      trendStatus: deliveriesPlanData?.from_actual <= 0.5 ? "success" : "error",
      totalScore: deliveriesPlanData?.total_returns,
      totalPlans: deliveriesPlanData?.total_plans,
      percentageFromActual: deliveriesPlanData?.from_actual,
      percentageFromPlan: deliveriesPlanData?.from_plan,
    },
    {
      title: "# of Missing",
      type: "other",
      Icon: NotInterested,
      trendStatus: deliveriesPlanData?.returns.prev.missing - deliveriesPlanData?.returns.actual.missing > 0 ? "success" : "error",
      totalScore: deliveriesPlanData?.returns.actual.missing,
      prevScore: deliveriesPlanData?.returns.prev.missing,
    },
  ];

  return {
    mockedInfoData,
  };
};
