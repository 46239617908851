export const roleDa = [
  { id: 1, name: "Standard", value: 0 },
  { id: 2, name: "Abscent", value: 1 },
  { id: 3, name: "Rescue", value: 2 },
  { id: 4, name: "Standby", value: 3 },
  { id: 5, name: "Training", value: 4 },
  { id: 6, name: "Quality", value: 5 },
  { id: 7, name: "Special", value: 6 },
];

export const roleFleet = [
  { id: 1, name: "Standard", value: 0 },
  { id: 2, name: "Standby", value: 1 },
  { id: 3, name: "Out of Service", value: 2 },
];

export const getRoleDaById = (number: number) => {
  return roleDa.find((el) => el.value === number)?.name;
};

export const getRoleFleetById = (number: number) => {
  return roleFleet.find((el) => el.value === number)?.name;
};

export const waveDa = [
  { id: 1, name: "Wave 1", value: 1 },
  { id: 2, name: "Wave 2", value: 2 },
  { id: 3, name: "Wave 3", value: 3 },
  { id: 4, name: "Wave 4", value: 4 },
  { id: 5, name: "Wave 5", value: 5 },
];
