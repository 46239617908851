import {
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  FormControl,
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Drawer
} from "@material-ui/core";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { inputNumberValidate, inputNumberValidateOnPaste } from "utils/formats";
import { 
  actStatusConstants,
  actActionConstants,
} from "constants/constants";
import { ActFilterAutoComplete } from "components/elements/ActFilterAutoComplete"
import { UserDataShort } from "types/User";
import api from "api";
import { useDialogStyles } from "components/modals/styles";
import {  OnChangeEvent } from "types/general";
import { ActFilters } from "types/Act";
import { IkeaActFilters } from "types/IkeaAct";

interface ReportFiltersDrawerProps {
  open: boolean;
  onClose: () => void;
  offTragetAreaConstants: string[];
  filters: ActFilters | IkeaActFilters;
  filterAction: (arg0: ActFilters | IkeaActFilters) => void;
}

const getSuperUsers = () => api.getSuperUsersList();
const getDriverUsers = () => api.getDriverList();

export const ActFiltersDrawer: FC<ReportFiltersDrawerProps> = ({
    open,
    onClose,
    offTragetAreaConstants,
    filters,
    filterAction 
  }) => {

  const dispatch = useDispatch();
  const dialogClasses = useDialogStyles();
  const classes = useFiltersStyles();

  const [ weekDate, setWeekDate ] = useState<string>(filters?.weekDate || "")
  const [ offTargetArea, setOffTargetArea ] = useState<string | null>(filters?.offTargetArea || "")
  const [ action, setAction ] = useState<number | null>(filters?.action)
  const [ driver, setDriver ] = useState<UserDataShort | null>(filters?.driver || null)
  const [ owner, setOwner ] = useState<UserDataShort | null>(filters?.owner || null)
  const [ status, setStatus ] = useState<number | null>(filters?.status)
  const [ dueDate, setDueDate ] = useState<string>(filters?.dueDate || "")
  const [ coachingCount, setCoachingCount ] = useState<number>(filters?.coachingCount)

  const handleFilterReset = () => {
    dispatch(
      filterAction({
        pageNumber: 0,
        weekDate: "",
        offTargetArea: null,
        action: 0,
        driver: null,
        owner: null,
        status: 0,
        dueDate: "",
        coachingCount: 0,
      })
    );
    setWeekDate("");
    setOffTargetArea(null)
    setAction(0);
    setDriver(null);
    setOwner(null);
    setStatus(0);
    setDueDate("");
    setCoachingCount(0);
  };

  const handleFilterSubmit = () => {
    dispatch(
      filterAction({
        pageNumber: 0,
        weekDate,
        offTargetArea,
        action,
        status,
        dueDate,
        coachingCount,
        owner,
        driver
      })
    );
    onClose();
  };

  return (
    <Drawer
      data-testid="testActFilterDrawer"
      anchor="right"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { maxWidth: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4">Filter by:</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
          }}
        >
            <Box style={{ width: "100%" }}>
              <FormControl fullWidth style={{ marginBottom: "8px" }}>
                  <TextField
                      inputProps={{ "data-testid": "actFilterActionWeek" }}
                      label="Action week"
                      variant="standard"
                      name="action_week"
                      type="week"
                      InputLabelProps={{ shrink: true }}
                      value={weekDate}
                      onChange={(e: OnChangeEvent)=>{setWeekDate(e.target.value)}}
                  />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: "8px" }}>
                  <InputLabel>Off Target Area</InputLabel> 
                  <Select
                      inputProps={{ "data-testid": "actFilterOffTargetArea" }}
                      name="off_target_area"
                      value={offTargetArea}
                      onChange={(e: OnChangeEvent)=>{setOffTargetArea(e.target.value)}}
                      placeholder="Off target area"
                  >
                      {
                          offTragetAreaConstants?.map((item:string)=>(
                              <MenuItem key={item} value={item}>{item}</MenuItem>
                          ))
                      }
                  </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: "8px" }}>
                  <InputLabel>Action</InputLabel> 
                  <Select
                      inputProps={{ "data-testid": "actFilterAction" }}
                      name="action"
                      value={Number(action)}
                      defaultValue={Number(action)}
                      onChange={(e: OnChangeEvent)=>{setAction(e.target.value)}}
                      placeholder="action"
                  >
                      {
                          actActionConstants?.map(({value, option}:{value:number, option:string})=>(
                              <MenuItem key={option} value={value}>{option}</MenuItem>
                          ))
                      }
                  </Select>
              </FormControl>
              <ActFilterAutoComplete
                label="DA name"
                name="da_name"
                defaultValue={filters?.driver}
                value={driver}
                onChange={setDriver}
                getOptions={getDriverUsers}
                getOptionLabel={(el: UserDataShort)=> `${el?.first_name} ${el?.last_name}`}
                className={classes.formControl}
              />
              <ActFilterAutoComplete
                label="Owner"
                name="owner"
                defaultValue={filters?.owner}
                value={owner}
                onChange={setOwner}
                getOptions={getSuperUsers}
                getOptionLabel={(el: UserDataShort)=> `${el?.first_name} ${el?.last_name}`}
                className={classes.formControl}
              />
              <FormControl fullWidth style={{ marginBottom: "8px" }}>
                  <TextField
                      inputProps={{ "data-testid": "actFilterDueDate" }}
                      label="Due date"
                      variant="standard"
                      name="due_date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={dueDate}
                      onChange={(e: OnChangeEvent)=>{setDueDate(e.target.value)}}
                  />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: "8px" }}>
                  <InputLabel>Status</InputLabel> 
                  <Select
                      inputProps={{ "data-testid": "actFilterStatus" }}
                      name="status"
                      placeholder="Status"
                      value={Number(status)}
                      defaultValue={Number(status)}
                      onChange={(e: OnChangeEvent)=>{setStatus(e.target.value)}}
                  >
                      {
                          actStatusConstants?.map(({value, option}:{value:number, option:string})=>(
                              <MenuItem key={option} value={value}>{option}</MenuItem>
                          ))
                      }
                  </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: "8px"}}>
                  <InputLabel>Coaching count</InputLabel> 
                  <Input
                      defaultValue={Number(filters?.coachingCount)}
                      name={`coaching_count`}
                      onKeyPress={inputNumberValidate}
                      onPaste={inputNumberValidateOnPaste}
                      value={Number(coachingCount)}
                      onChange={(e:OnChangeEvent)=>{setCoachingCount(Number(e.target.value))}}
                      inputProps={{
                          style: { 
                            textAlign: "center", padding: "0 0 1px 0", maxWidth: "50px", height:"31px",
                          },
                          "data-testid": "actFilterCoahcingCount",
                      }}
                  />
              </FormControl>
            </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ padding: "32px 24px", gap: "20px", }}
      >
        <Button
          className={dialogClasses.actionButton}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className={dialogClasses.actionButton}
          onClick={handleFilterReset} color="secondary"
        >
          Reset
        </Button>
        <Button
          className={dialogClasses.actionButton}
          onClick={handleFilterSubmit}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Drawer>
  );
};

const useFiltersStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
  },
}));