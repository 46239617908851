import { FC } from "react";
import { Paper, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { changeDailyDAListType } from "actions/daDailyReportsAction";
import { BudgetListFilter } from "./BudgetsListFilter";
import { ReportsListFilter } from "./ReportsListFilter";
import { MANAGER, SUPERADMIN } from "constants/constants";

interface ReportsBudgetaHeadProps {}

export const ReportsBudgetaHead: FC<ReportsBudgetaHeadProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { pageListType } = useTypeSelectors((state) => state.dasBoard);

  return (
    <Paper className={classes.filter}>
      <Box className={classes.buttonBox}>
        {pageListType === "type-shifts" ? <ReportsListFilter /> : <BudgetListFilter />}
      </Box>
      {[MANAGER, SUPERADMIN].includes(userData.role) ? (
        <Box className={classes.typeButtons}>
          <Button
            variant={pageListType === "type-shifts" ? "contained" : "outlined"}
            className={classes.leftButton}
            onClick={() => dispatch(changeDailyDAListType("type-shifts"))}
          >
            Shift reports
          </Button>
          <Button
            variant={pageListType === "type-budget" ? "contained" : "outlined"}
            className={classes.rightButton}
            onClick={() => dispatch(changeDailyDAListType("type-budget"))}
          >
            Budgets
          </Button>
        </Box>
      ) : null}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  filter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
    "@media (max-width: 1100px)": {
      padding: theme.spacing(3, 1, 1),
      gap: 10,
      flexDirection: "column",
    },
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "space-between",
    gap: "10px",
    "@media (max-width: 1100px)": {
      flexDirection: "column",
    },
  },
  typeButtons: {
    display: "flex",
  },
  leftButton: {
    boxSizing: "border-box",
    borderRadius: "25px 0 0 25px",
  },
  rightButton: {
    boxSizing: "border-box",
    borderRadius: "0 25px 25px 0",
  },
}));
