import { Button } from "@material-ui/core";
import { FC } from "react";
import { useStyles } from "./styles";

interface DialogButtonProps {
  testId: string;
  buttonName: string;
  customStyle?: any;
  onClick: () => void;
  color?: "primary" | "secondary" | "default" | undefined;
  variant?: "text" | "outlined" | "contained";
  disabled?: boolean;
}

export const DialogButton: FC<DialogButtonProps> = ({
  testId,
  buttonName,
  customStyle,
  color,
  variant,
  onClick,
  disabled,
}) => {
  const classes = useStyles();
  
  return (
    <Button
      data-testid={testId}
      disabled={disabled}
      className={classes.actionButton}
      style={customStyle}
      color={color}
      variant={variant}
      onClick={onClick}
    >
      {buttonName}
    </Button>
  );
};
