import { DasBoardState } from "types/Report";
import { DasBoardConstants } from "../constants/constants";
import { ReducersInterfaceAction } from "types/Reducers";
import { defaultReportFilters, initialReportState } from "store/initialStates";

export const dasBoardReducer = (
  state = initialReportState,
  action: ReducersInterfaceAction,
): DasBoardState => {
  switch (action.type) {
    case DasBoardConstants.DAS_DAILY_REPORT_PLAN_UPDATE_PENDING:
    case DasBoardConstants.DAS_DAILY_REPORTS_PENDING:
    case DasBoardConstants.DAS_DAILY_REPORT_CREATE_PENDING:
    case DasBoardConstants.DAS_DAILY_REPORT_DELETE_PENDING:
    case DasBoardConstants.DAS_DAILY_REPORT_GET_PENDING:
      return {
        ...state,
        reportListLoading: true,
      };
    case DasBoardConstants.DAS_DAILY_REPORT_PLAN_UPDATE_ERROR:
    case DasBoardConstants.DAS_DAILY_REPORTS_ERROR:
    case DasBoardConstants.DAS_DAILY_REPORT_CREATE_ERROR:
    case DasBoardConstants.DAS_DAILY_REPORT_DELETE_ERROR:
    case DasBoardConstants.DAS_DAILY_REPORT_GET_ERROR:
      return {
        ...state,
        reportListLoading: false,
      };
    case DasBoardConstants.DAS_DAILY_ADD_REPORT_FIELD:
    case DasBoardConstants.DAS_DAILY_UPDATE_REPORT_FIELD:
    case DasBoardConstants.DAS_DAILY_DELETE_REPORT_FIELD:
      return {
        ...state,
        currentReport: action.payload,
      };
    case DasBoardConstants.DAS_DAILY_REPORTS_SUCCESS:
      return {
        ...state,
        reportList: action.payload.results,
        reportListCount: action.payload.count,
        reportListLoading: false,
      };
    case DasBoardConstants.DAS_DAILY_REPORT_CREATE_SUCCESS:
      return {
        ...state,
        reportListLoading: false,
        reportList: [...state.reportList, action.payload],
        currentReportDate: action.payload.report_date,
        currentReportStation: action.payload.station,
      };
    case DasBoardConstants.DAS_DAILY_REPORT_PLAN_UPDATE_SUCCESS:
      return {
        ...state,
        reportListLoading: false,
        reportPlans: action.payload,
      };
    case DasBoardConstants.DAS_DAILY_REPORT_DELETE_SUCCESS:
      return {
        ...state,
        reportListLoading: false,
        reportList: action.payload,
      };
    case DasBoardConstants.DAS_DAILY_REPORT_GET_SUCCESS:
      return {
        ...state,
        reportListLoading: false,
        currentReportId: action.payload.id,
        currentReport: action.payload.records,
        currentReportDate: action.payload.report_date,
        currentReportStation: action.payload.station,
        currentReportDispatcher: action.payload.dispatcher,
        reportPlans: {
          plan_no_of_routes: action.payload.plan_no_of_routes,
          plan_no_of_deliveries: action.payload.plan_no_of_deliveries,
          plan_no_of_da: action.payload.plan_no_of_da,
          plan_no_of_fleet: action.payload.plan_no_of_fleet,
        }
      };
    case DasBoardConstants.DAS_DAILY_REPORTS_FILTERS_SET:
      return {
        ...state,
        reportListFilters: { ...state.reportListFilters, ...action.payload },
      };
    case DasBoardConstants.DAS_DAILY_REPORTS_FILTERS_RESET:
      return {
        ...state,
        reportListFilters: defaultReportFilters,
      };
    case DasBoardConstants.DAS_DAILY_REPORTS_FILTERS_TAB_SET:
      return {
        ...state,
        reportListFiltersTab: action.payload,
      };
    case DasBoardConstants.DAS_FLEET_SIZE_PENDING:
      return {
        ...state,
        fleetSizeLoading: true,
      };
    case DasBoardConstants.DAS_FLEET_SIZE_SUCCESS:
      return {
        ...state,
        fleetSizeLoading: false,
        fleetSizes: action.payload,
      };
    case DasBoardConstants.DAS_FLEET_SIZE_ERROR:
      return {
        ...state,
        fleetSizeLoading: false,
      };
    case DasBoardConstants.DAS_TABLE_ROWS_PER_PAGE:
      return {
        ...state,
        dasBoardRowsPerPage: action.payload,
      };
    case DasBoardConstants.DAS_UPLOAD_FILE_INFO_PENDING:
      return {
        ...state,
        uploadFileLoading: true,
      };
    case DasBoardConstants.DAS_UPLOAD_FILE_INFO_SUCCESS:
    case DasBoardConstants.DAS_UPLOAD_FILE_INFO_ERROR:
      return {
        ...state,
        uploadFileLoading: false,
      };
    case DasBoardConstants.CHANGE_PAGE_LIST_TYPE:
      return {
        ...state,
        pageListType: action.payload,
      };
    default:
      return state;
  }
};
