import { FC, useState, useEffect } from "react";
import { Box, Button, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useDispatch } from "react-redux";
import { handleUpdatePlan } from "actions/daDailyReportsAction";
import { getTimeSincePost } from "utils/filters";
import { InfoBox } from "components/elements/InfoBox";

interface ReportPlanHeadProps {}

export const ReportPlanHead: FC<ReportPlanHeadProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { reportPlans, currentReportDate, currentReportId } = useTypeSelectors(
    (state) => state.dasBoard,
  );
  const [disabledByDate, setDisabledByDate] = useState(getTimeSincePost(currentReportDate));
  const [canEdit, setCanEdit] = useState(false);

  const [planNoOfRoutes, setPlanNoOfRoutes] = useState(Number(reportPlans?.plan_no_of_routes));
  const [planNoOfDeliveries, setPlanNoOfDeliveries] = useState(
    Number(reportPlans?.plan_no_of_deliveries),
  );
  const [planNoOfDA, setPlanNoOfDA] = useState(Number(reportPlans?.plan_no_of_da));
  const [planNoOfFleets, setPlanNoOfFleets] = useState(Number(reportPlans?.plan_no_of_fleet));

  const conditionForDisabledEdit = userData?.can_create_old_shifts ? false : disabledByDate;

  useEffect(() => {
    if (currentReportDate) {
      setDisabledByDate(getTimeSincePost(currentReportDate));
    }
  }, [currentReportDate]);

  useEffect(() => {
    setPlanNoOfRoutes(Number(reportPlans?.plan_no_of_routes))
    setPlanNoOfDeliveries(Number(reportPlans?.plan_no_of_deliveries))
    setPlanNoOfDA(Number(reportPlans?.plan_no_of_da))
    setPlanNoOfFleets(Number(reportPlans?.plan_no_of_fleet))
  }, [reportPlans]);

  const sumbitPlanValues = () => {
    dispatch(
      handleUpdatePlan(currentReportId, {
        plan_no_of_routes: planNoOfRoutes,
        plan_no_of_deliveries: planNoOfDeliveries,
        plan_no_of_da: planNoOfDA,
        plan_no_of_fleet: planNoOfFleets,
      }),
    );
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4">Plans:</Typography>
      {canEdit ? (
        <Box className={classes.activePlanSection}>
          <TextField
            label="No of Routes"
            name="plan_no_of_routes"
            type="number"
            disabled={conditionForDisabledEdit}
            value={planNoOfRoutes}
            onChange={(e) => setPlanNoOfRoutes(Number(e.target.value))}
          />
          <TextField
            label="No of Deliveries"
            name="plan_no_of_deliveries"
            type="number"
            disabled={conditionForDisabledEdit}
            value={planNoOfDeliveries}
            onChange={(e) => setPlanNoOfDeliveries(Number(e.target.value))}
          />
          <TextField
            label="No of DA"
            name="plan_no_of_da"
            type="number"
            disabled={conditionForDisabledEdit}
            value={planNoOfDA}
            onChange={(e) => setPlanNoOfDA(Number(e.target.value))}
          />
          <TextField
            label="No of Fleet"
            name="plan_no_of_fleet"
            type="number"
            disabled={conditionForDisabledEdit}
            value={planNoOfFleets}
            onChange={(e) => setPlanNoOfFleets(Number(e.target.value))}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              sumbitPlanValues();
              setCanEdit(false);
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="inherit"
            className={classes.button}
            onClick={() => setCanEdit(false)}
          >
            Exit editing
          </Button>
        </Box>
      ) : (
        <Box className={classes.inactivePlanSection}>
          <InfoBox label={"No of Routes"} value={planNoOfRoutes} />
          <InfoBox label={"No of Deliveries"} value={planNoOfDeliveries} />
          <InfoBox label={"No of DA"} value={planNoOfDA} />
          <InfoBox label={"No of Fleet"} value={planNoOfFleets} />
          {!conditionForDisabledEdit && (
            <Button
              variant="contained"
              color="inherit"
              className={classes.button}
              onClick={() => setCanEdit(true)}
            >
              Edit
            </Button>
          )}
        </Box>
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(5),
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    flexWrap: "wrap",
    borderRadius: 15,
    boxShadow: "none",
    borderBottom: "#e8e9ee 2px solid",
  },
  activePlanSection: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },
  inactivePlanSection: {
    display: "flex",
    gap: theme.spacing(3),
    alignItems: "center",
  },
  button: {
    textTransform: "none",
  },
}));
