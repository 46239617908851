import { FC, useEffect } from "react";
import { TablePagination } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { ModalWindow } from "components/templateComponents/modalWindow";
import { AddBudgetModal } from "components/modals/modalContent/AddBudgetModal";
import { createBudgetAction, getAllBudgets, setBudgetPageFilters } from "actions/budgetActions";
import { AddBudgetData } from "types/Budgets";

interface ReportsBudgetaHeadProps {}

export const BudgetListFilter: FC<ReportsBudgetaHeadProps> = () => {
  const dispatch = useDispatch();
  const { budgetsFilters, budgeListCount } = useTypeSelectors((state) => state.budgetBoard);

  const handleChangePagination = (event: unknown, value: number) => {
    dispatch(setBudgetPageFilters({ pageNumber: Number(value) }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setBudgetPageFilters({ rowsPerPage: Number(event.target.value) }));
  };

  useEffect(() => {
    dispatch(
      getAllBudgets({
        pageNumber: budgetsFilters?.pageNumber + 1,
        rowsPerPage: budgetsFilters?.rowsPerPage,
      }),
    );
  }, [budgetsFilters?.rowsPerPage, budgetsFilters?.pageNumber, dispatch]);

  const onAddBudget = (data: AddBudgetData) => {
    dispatch(createBudgetAction(data));
  };

  return (
    <>
      <ModalWindow modal_name={"add-budget-modal"} modalTitle={"Add budget"}>
        <AddBudgetModal onSubmit={onAddBudget} />
      </ModalWindow>
      <TablePagination
        page={budgetsFilters?.pageNumber}
        rowsPerPage={budgetsFilters?.rowsPerPage}
        count={budgeListCount}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePagination}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
      />
    </>
  );
};
