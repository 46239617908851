import { useDispatch } from "react-redux";
import { useDialogStyles } from "../styles";
import { closeModal } from "actions/modalActions";
import { DialogActions } from "@material-ui/core";
import { DialogButton } from "components/buttons/DialogButton";
import { Colors } from "constants/constants";
import { FC } from "react";

interface ShiftDialogProps {
    onSubmit?: () => void;
  }

export const DeleteBudgetDialog: FC<ShiftDialogProps> = ({ onSubmit }) => {
    const classes = useDialogStyles();
    const dispatch = useDispatch();
  
    const onClose = () => dispatch(closeModal());
  
    const handleSubmit = () => {
      onClose();
      if (onSubmit) onSubmit();
    };
  
    return (
      <>
        <DialogActions data-testid="budgetDeleteModal" className={classes.dialogActions}>
          <DialogButton
            testId={"cancelBudgetDialogButton"}
            buttonName={"Cancel"}
            onClick={onClose}
            customStyle={{ background: Colors.GRAY }}
          />
          <DialogButton
            testId={"deleteBudgetDialogButton"}
            buttonName={"Delete"}
            onClick={handleSubmit}
            customStyle={{ background: Colors.RED, color: "#fff" }}
          />
        </DialogActions>
      </>
    );
  };