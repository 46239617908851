import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { localUserService } from "utils/localStorage";
import { DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR } from "constants/constants";
import VisitPage from "pages/VisitPage/VisitPage";
import Dashboard from "pages/Dashboard/Dashboard";
import Account from "pages/Account/Account";
import AmazonImport from "pages/Import/AmazonImport";
import DaBoardPage from "pages/DaBoard/DaBoardPage";
import Scorecard from "pages/Scorecard/Scorecard";
import RouteWithBars from "./components/RouteWithBars";
import ReportPage from "pages/DaBoard/ReportPage";
import CheckPage from "pages/Check/CheckPage";
import CheckWeekPage from "pages/Check/CheckWeekPage";
import ActPage from "pages/Act/ActPage";
import IkeaCheckIkeaPage from "pages/IkeaCheck/IkeaCheckPage";
// import IkeaCheckIkeaWeekPage from "pages/IkeaCheck/IkeaCheckWeekPage";
// import IkeaActPage from "pages/IkeaAct/IkeaActPage";
import AmznScoreboardPage from "pages/AmznScoreboard/AmznScoreboardPage";
import CustomGraphsPage from "pages/CustomGraphs/CustomGrahpsPage";
import PerformancePage from "pages/Performance/PerformancePage";

const Router = () => {
  const token = localUserService.getAccess();
  const permissionKey = localUserService.getPermissions();
  const permissionKeyNumber = permissionKey ? permissionKey : 1;

  return (
    <BrowserRouter>
      <Toaster />
      <Switch>
        <Route exact path="/">
          {token && permissionKey ? (
            permissionKey !== DISPATCHER ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/das-reports" />
            )
          ) : (
            <VisitPage />
          )}
        </Route>
        <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[DISPATCHER, SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/account"
          component={Account}
        />
        <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/dashboard"
          component={Dashboard}
        />
        <RouteWithBars
          exact
          permission={permissionKeyNumber}
          permissionKey={[DISPATCHER, SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/das-reports"
          component={DaBoardPage}
        />
        <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[DISPATCHER, SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/das-reports/:id"
          component={ReportPage}
        />
        <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/import"
          component={AmazonImport}
        />
        <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[SUPERADMIN, MANAGER, SUPERVISOR]}
          path="/scorecard"
          component={Scorecard}
        />
        <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/amzn-scoreboard"
          component={AmznScoreboardPage}
        />
        <RouteWithBars
          exact
          permission={permissionKeyNumber}
          permissionKey={[DISPATCHER, SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/check"
          component={CheckPage}
        />
        <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[DISPATCHER, SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/check/:id"
          component={CheckWeekPage}
        />
        <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[DISPATCHER, SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/act"
          component={ActPage}
        />
        <RouteWithBars
          exact
          permission={permissionKeyNumber}
          permissionKey={[DISPATCHER, SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/check-ikea"
          component={IkeaCheckIkeaPage}
        />
        {/* <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[DISPATCHER, SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/check-ikea/:id"
          component={IkeaCheckIkeaWeekPage}
        />
        <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[DISPATCHER, SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/act-ikea"
          component={IkeaActPage}
        /> */}
        <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[SUPERVISOR, MANAGER, SUPERADMIN]}
          path="/Performance"
          component={PerformancePage}
        />
        {/* <RouteWithBars
          permission={permissionKeyNumber}
          permissionKey={[SUPERADMIN]}
          path="/graphs"
          component={CustomGraphsPage}
        /> */}
        <Route path="/*/">
          <>Not Found</>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
