import { FC, useState, FocusEvent } from "react";
import { useDispatch } from "react-redux";
import { TableCell, TableRow, Typography, TextField } from "@material-ui/core";
import moment from "moment";
import { useReportsStyles } from "./styles";
import { ModalWindow } from "components/templateComponents/modalWindow";
import { deleteBudgetAction, handleUpdateBudgetRow } from "actions/budgetActions";
import DeleteIcon from "@material-ui/icons/Delete";
import { DeleteBudgetDialog } from "components/modals/modalContent/deleteBudgetModal";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { BudgetRow } from "types/Budgets";

interface BudgetListRowProps {
  row: BudgetRow;
}

export const BudgetListRow: FC<BudgetListRowProps> = ({ row }) => {
  const classes = useReportsStyles();

  const {
    id,
    month,
    year,
    station,
    budget_no_of_routes,
    budget_no_of_deliveries,
    budget_no_of_da,
  } = row;

  const [noOfRoutes, setNoOfRoutes] = useState(budget_no_of_routes);
  const [noOfDeliveries, setNoOfDeliverie] = useState(budget_no_of_deliveries);
  const [noOfDA, setNoOfDA] = useState(budget_no_of_da);
  const [noOfFleet, setNoOfFleet] = useState(row.budget_no_of_routes);
  const { userData } = useTypeSelectors((state) => state.auth);
  const dispatch = useDispatch();

  const conditionDisableEdit = month !== moment().month()+1

  const updateValueBlur = (event: FocusEvent<HTMLInputElement>) => {
      dispatch(handleUpdateBudgetRow(id, { [event.target.name]: event.target.value }))
  };

  const handleDeleteBudget = (id:number) => {
    dispatch(deleteBudgetAction(id))
  }

  return (
    <TableRow hover>
      <TableCell className={classes.tableBodyCell}>
        <Typography variant="body2">{`${month}-${year}`}</Typography>
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <Typography variant="body2">{userData.station_spots.find((item) => item?.station.id === station)?.station.title}</Typography>
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <TextField
          name="budget_no_of_routes"
          type="number"
          defaultValue={noOfRoutes}
          value={noOfRoutes}
          onChange={(e) => {setNoOfRoutes(Number(e.target.value))}}
          onBlur={updateValueBlur}
          disabled={conditionDisableEdit}
        />
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <TextField
          name="budget_no_of_deliveries"
          type="number"
          defaultValue={noOfDeliveries}
          value={noOfDeliveries}
          onChange={(e) => {setNoOfDeliverie(Number(e.target.value))}}
          onBlur={updateValueBlur}
          disabled={conditionDisableEdit}
        />
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <TextField
          name="budget_no_of_da"
          type="number"
          defaultValue={noOfDA}
          value={noOfDA}
          onChange={(e) => {setNoOfDA(Number(e.target.value))}}
          onBlur={updateValueBlur}
          disabled={conditionDisableEdit}
        />
      </TableCell>
      <TableCell className={classes.tableBodyCell}>
        <TextField
          name="budget_no_of_fleet"
          type="number"
          defaultValue={noOfFleet}
          value={noOfFleet}
          onChange={(e) => {setNoOfFleet(Number(e.target.value))}}
          onBlur={updateValueBlur}
          disabled={conditionDisableEdit}
        />
      </TableCell>
      <TableCell>
        <ModalWindow
          modal_name={`delete-budget-${row?.id}`}
          modalTitle={"Delete Board"}
          modalDescription={"Attention! This action will delete this entry forever"}
          modalText={"Are you sure to delete this budget?"}
          isIconButton
          hasIcon
          theme="red"
          icon={<DeleteIcon />}
        >
          <DeleteBudgetDialog onSubmit={() => handleDeleteBudget(row?.id)} />
        </ModalWindow>
      </TableCell>
    </TableRow>
  );
};
