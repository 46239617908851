import { BudgetConstants } from "../constants/constants";
import { ReducersInterfaceAction } from "types/Reducers";
import { initialBudgetState } from "store/initialStates";
import { BudgetsState } from "types/Budgets";

export const budgetReducer = (
  state = initialBudgetState,
  action: ReducersInterfaceAction,
): BudgetsState => {
  switch (action.type) {
    case BudgetConstants.BUDGETS_LOADING:
      return {
        ...state,
        loadingData: true,
      };
    case BudgetConstants.BUDGET_CREATE_ACTION_SUCCESS:
      return {
        ...state,
        loadingData: false,
        budgetList: action.payload,
        budgeListCount: action.payload.length,
      };
    case BudgetConstants.BUDGETS_GET_ALL_SUCSESS:
    case BudgetConstants.BUDGETS_DELETE_BUDGET_SUCSESS:
      return {
        ...state,
        loadingData: false,
        budgetList: action.payload.dataList,
        budgeListCount: action.payload.count,
      };
    case BudgetConstants.BUDGETS_UPDATE_BUDGET_SUCSESS:
      return {
        ...state,
        loadingData: false,
        budgetList: action.payload,
      };
    case BudgetConstants.BUDGETS_ERROR:
      return {
        ...state,
        loadingData: false,
      };
    case BudgetConstants.BUDGETS_TABLE_ROWS_PER_PAGE:
      return {
        ...state,
        loadingData: false,
        budgetsFilters: {
          ...state?.budgetsFilters,
          ...action.payload
        }
      };
    default:
      return state;
  }
};
