import { FC } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReportsListTable } from "components/tables/ReportTables/ReportsListTable";
import { ReportsBudgetaHead } from "components/tables/ReportTables/ReportsBudgetaHead";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { BudgetsListTable } from "components/tables/ReportTables/BudgetsListTable";

const DaBoardPage: FC = () => {
  const classes = useStyles();
  const { pageListType } = useTypeSelectors((state) => state.dasBoard);

  return (
    <Box className={classes.root} id="Table">
      <ReportsBudgetaHead />
      {pageListType === "type-shifts" ? <ReportsListTable /> : <BudgetsListTable />}
    </Box>
  );
};

export default DaBoardPage;

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));
