import { FC, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { getReportAction } from "actions/daDailyReportsAction";
import { ReportTable } from "components/tables/ReportTables/ReportTable";
import { ReportFilter } from "components/tables/ReportTables/ReportFilter";
import { ReportTableTotal } from "components/elements/ReportTableTotal";
import { useNav } from "hooks/useNav";
import { ReportPlanHead } from "components/tables/ReportTables/ReportPlanHead";
import { MANAGER, SUPERADMIN } from "constants/constants";
import { useTypeSelectors } from "hooks/useTypeSelectors";

const ReportPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useNav().pageParams;
  const { userData } = useTypeSelectors((state) => state.auth);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (id) dispatch(getReportAction(id));
  }, [id, dispatch]);

  if (!id) return null;
  return (
    <Box className={classes.root} id="Table">
      <ReportFilter
        currentReportId={id}
        searchValue={searchValue}
        onSearchValueChange={setSearchValue}
      />
      {[MANAGER, SUPERADMIN].includes(userData.role) ? <ReportPlanHead /> : null}
      <ReportTable searchValue={searchValue} />
      <ReportTableTotal />
    </Box>
  );
};

export default ReportPage;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));
