import { Box, Typography } from "@material-ui/core";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Truck from "@material-ui/icons/LocalShipping";
import { Doughnut } from "react-chartjs-2";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { handleGetFleetStatusesScores } from "./requests";
import { useStyles } from "../style";
import { formatNumberType } from "utils/formatNUmber";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface FleetStatusGraphProps {}

export const FleetStatusGraph: FC<FleetStatusGraphProps> = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState<any | null>(null);
  const { dashboardScoreSettings } = useTypeSelectors((state) => state.dashboard);
  const currentStation = dashboardScoreSettings?.currentStation || "1";

  const currentYear = moment().format("yyyy");

  useEffect(() => {
    const params = {
      stations: dashboardScoreSettings?.currentStations?.join("_"),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "annual" && {
        two_year: Number(dashboardScoreSettings?.labelDate?.year),
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "month" && {
        two_month: `${dashboardScoreSettings?.labelDate?.year}-${dashboardScoreSettings?.labelDate?.month}-01`,
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "week" && {
        two_week: Number(dashboardScoreSettings?.labelDate?.week),
        year: dashboardScoreSettings?.labelDate?.year
          ? +dashboardScoreSettings?.labelDate?.year
          : +currentYear,
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "day" && {
        two_day: dashboardScoreSettings?.labelDate?.day,
      }),
    };
    handleGetFleetStatusesScores(params, setLoading, setRequestData);
  }, [
    currentStation,
    dashboardScoreSettings?.currentDateTypeLabelDateType,
    dashboardScoreSettings?.currentStation,
    dashboardScoreSettings?.labelDate,
    dashboardScoreSettings?.labelDate?.week,
    dashboardScoreSettings?.labelDate?.month,
    dashboardScoreSettings?.labelDate?.year,
    currentYear,
    dashboardScoreSettings?.currentStations,
  ]);

  const data = [
    {
      label: `Utilized - ${requestData?.number_of_standart}`,
      value: requestData?.number_of_standart,
      color: "#5a9bd5",
      cutout: "50%",
    },
    {
      label: `Stand By - ${requestData?.number_of_standby}`,
      shortLabel: "Stand By",
      value: requestData?.number_of_standby,
      color: "#a4a4a4",
      cutout: "50%",
    },
    {
      label: `Out of Service - ${requestData?.number_of_out_of_service}`,
      shortLabel: "Out of Service",
      value: requestData?.number_of_out_of_service,
      color: "#ed7c30",
      cutout: "50%",
    },
    {
      label: `Idle - ${requestData?.number_of_idle}`,
      shortLabel: "Idle",
      value: requestData?.number_of_idle,
      color: "#febf01",
      cutout: "50%",
    },
  ];

  const dataChart = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => Math.round(item.value)),
        backgroundColor: data.map((item) => item.color),
        borderColor: "white",
        borderWidth: 2,
        dataVisibility: new Array(data.length).fill(true),
      },
    ],
  };

  const options = {
    aspectRatio: 1,
    plugins: {
      legend: {
        display: true,
        responsive: true,
        position: "right",
        labels: {
          boxWidth: 12,
          padding: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = "";
            if (context.parsed) {
              label = context.label.replace(" -", ":");
            }
            return label;
          },
        },
      },
      datalabels: {
        formatter: (value: number) => {
          let val = Math.round(value);
          return new Intl.NumberFormat("tr-TR").format(val);
        },
        color: "white",

        font: {
          weight: "bold",
          size: 14,
          family: "poppins",
        },
      },
      responsive: true,
    },
    cutout: data.map((item) => item.cutout),
  };

  const plugins = [
    {
      beforeDraw: (chart: any) => {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 200).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        const text = `${formatNumberType(Number(requestData?.utilized_percentage))}%`,
          textX = Math.round((width - ctx.measureText(text).width) / 2) - 70,
          textY = height / 2.2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <Box
      className={classes.graphPaper}
      style={{
        boxSizing: "border-box",
        position: "relative",
        alignItems: "center",
      }}
    >
      <Box className={classes.graphHead}>
        <Typography className={classes.graphHeadTitle} style={{ fontWeight: 600 }} variant="h5">
          Fleet Status
        </Typography>
        <Truck className={classes.graphHeadIcon} />
      </Box>
      {!loading && (
        //@ts-ignore
        <Doughnut options={options} data={dataChart} plugins={plugins} />
      )}
    </Box>
  );
};
