import { FC, useState, useEffect, useCallback } from "react";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import moment from "moment";
import { inputNumberValidateOnPaste } from "utils/formats";
import { useDispatch } from "react-redux";
import { closeModal } from "actions/modalActions";
import { useDialogStyles } from "../styles";
import { Colors } from "constants/constants";
import { OnChangeEvent } from "types/general";
import { DialogButton } from "components/buttons/DialogButton";
import { AddBudgetData, BudgetRow } from "types/Budgets";
import { StationSpot } from "types/DataTypes";

export type Station = {
  id: number;
  title: string;
};

export type AddBudgetModalParams = {
  station: number;
  date: string;
  rows: number;
  planRoutes: number;
  planDeliveries: number;
  planDA: number;
  planFleet: number;
};

interface AddBudgetModalProps {
  onSubmit: (data: AddBudgetData) => void;
}

export const AddBudgetModal: FC<AddBudgetModalProps> = ({ onSubmit }) => {
  const classes = useDialogStyles();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { budgetList } = useTypeSelectors((state) => state.budgetBoard);
  const { isOpen } = useTypeSelectors((state) => state.modalState);
  const defaultMonth = `${moment().year()}-${moment().month() + 1}`;

  const [station, setStation] = useState<number | null>(null);
  const [month, setMonth] = useState<string>(defaultMonth);
  const [planRoutes, setPlanRoutes] = useState(0);
  const [planDeliveries, setPlanDeliveries] = useState(0);
  const [planDA, setPlanDA] = useState(0);
  const [planFleet, setPlanFleet] = useState(0);

  const dispatch = useDispatch();

  const conditionStation = useCallback(
    (val: number | null) => {
      const budget = budgetList.some((budget: BudgetRow) =>
        month === defaultMonth
          ? budget.month <= moment().month() + 1 && val === budget.station
          : budget.month === Number(month.split("-")[1]) && val === budget.station,
      );
      return budget;
    },
    [month, budgetList, defaultMonth],
  );

  const onClose = () => dispatch(closeModal());

  const handleSubmit = () => {
    const inputYear = month.split("-")[0];
    const inputMonth = month.split("-")[1];
    if (inputYear && inputMonth) {
      onClose();
      onSubmit({ station, inputYear, inputMonth, planRoutes, planDeliveries, planDA, planFleet });
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setStation(null);
      setMonth(defaultMonth);
    }
  }, [isOpen, defaultMonth]);

  return (
    <>
      <DialogContent data-testid="testAddBudgetModalModal">
        <DialogContentText
          id="alert-dialog-description"
          style={{
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl style={{ marginBottom: 20 }}>
            <InputLabel>Select station</InputLabel>
            <Select
              inputProps={{ "data-testid": "budgetStationInput" }}
              label="Select station"
              name="station"
              value={station}
              onChange={(e: OnChangeEvent) => setStation(e.target.value as number)}
              placeholder="Choose the Station"
            >
              {userData.station_spots ? (
                userData?.station_spots?.map((el: StationSpot) => (
                  <MenuItem
                    key={el.station.id}
                    value={el.station.id}
                    disabled={conditionStation(el.station.id)}
                  >
                    {el.station.title}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={0} value={0}></MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl style={{ marginBottom: 20 }}>
            <TextField
              label="Assign to Month"
              name="assign_to_month"
              type="month"
              value={month}
              onChange={(e: OnChangeEvent) => setMonth(e.target.value)}
            />
          </FormControl>
          <FormControl style={{ marginBottom: 20 }}>
            <TextField
              label="Number of Routes"
              name="budget_no_of_routes"
              type="number"
              value={planRoutes}
              onChange={(e: OnChangeEvent) => setPlanRoutes(Number(e.target.value))}
              onPaste={inputNumberValidateOnPaste}
            />
          </FormControl>
          <FormControl style={{ marginBottom: 20 }}>
            <TextField
              label="Number of Deliveries"
              name="budget_no_of_deliveries"
              type="number"
              value={planDeliveries}
              onChange={(e: OnChangeEvent) => setPlanDeliveries(Number(e.target.value))}
              onPaste={inputNumberValidateOnPaste}
            />
          </FormControl>
          <FormControl style={{ marginBottom: 20 }}>
            <TextField
              label="Number of DA"
              name="budget_no_of_da"
              type="number"
              value={planDA}
              onChange={(e: OnChangeEvent) => setPlanDA(Number(e.target.value))}
              onPaste={inputNumberValidateOnPaste}
            />
          </FormControl>
          <FormControl style={{ marginBottom: 20 }}>
            <TextField
              label="Number of Fleet"
              name="budget_no_of_fleet"
              type="number"
              value={planFleet}
              onChange={(e: OnChangeEvent) => setPlanFleet(Number(e.target.value))}
              onPaste={inputNumberValidateOnPaste}
            />
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions data-testid="testReportCreateModalActions" className={classes.dialogActions}>
        <DialogButton
          testId={"cancelAddBudgetButton"}
          buttonName={"Cancel"}
          onClick={onClose}
          customStyle={{ background: Colors.GRAY }}
        />
        <DialogButton
          testId={"addBudgetButton"}
          buttonName={"Add"}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!(station && month && planDA && planDeliveries && planFleet && planRoutes)}
        />
      </DialogActions>
    </>
  );
};
