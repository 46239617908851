import { FC } from "react";
import { Box, Typography, Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { budgetsTableList } from "components/tables/tableRows";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { EMPTY } from "constants/constants";
import { useReportsStyles } from "./styles";
import { useTableFilters } from "hooks/useTableFilters";
import { SortArrow } from "components/elements/SortArrow";
import { BudgetListRow } from "./BudgetListRow";
import { BudgetRow, BudgetsTableHeadRow } from "types/Budgets";

interface BudgetsListTableProps {}

const SORT_OPTIONS = ["report_date", "created", "modified"];

export const BudgetsListTable: FC<BudgetsListTableProps> = () => {
  const classes = useReportsStyles();

  const { budgetList } = useTypeSelectors((state) => state.budgetBoard);
  const { sort, setSort } = useTableFilters("report_date", SORT_OPTIONS);

  return (
    <Box className={classes.root}>
      {!budgetList?.length ? (
        <Typography variant="h1" className={classes.emptyTable}>
          {EMPTY}
        </Typography>
      ) : (
        <>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {budgetsTableList.map((TableRows: BudgetsTableHeadRow) =>
                    SORT_OPTIONS.includes(TableRows.id) ? (
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => setSort(TableRows.id)}
                        key={TableRows.id}
                        style={{ cursor: "pointer" }}
                      >
                        <Box className={classes.sortCell}>
                          <Typography className={classes.tableCellTypography} variant="body2">
                            {TableRows.label}
                          </Typography>
                          <SortArrow id={TableRows.id} sort={sort} availableSort={SORT_OPTIONS} />
                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableCell} key={TableRows.id}>
                        <Box className={classes.sortCell}>
                          <Typography className={classes.tableCellTypography} variant="body2">
                            {TableRows.label}
                          </Typography>
                          <SortArrow id={TableRows.id} sort={sort} availableSort={SORT_OPTIONS} />
                        </Box>
                      </TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {budgetList.length
                  ? budgetList.map((row: BudgetRow) => <BudgetListRow row={row} key={row.id} />)
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};
