import { FC, SetStateAction, useEffect, useState } from "react";
import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { OnChangeEvent } from "types/general";

interface AutoCompleteProps {
  label: string;
  value: any;
  placeholder?: string;
  onChange: SetStateAction<any>;
  getOptions: () => Promise<any>;
  renderOption: (val: any) => string;
  className?: string;
}

export const AutoComplete: FC<AutoCompleteProps> = (props) => {
  const { getOptions } = props;

  const [localLoading, setLocalLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setLocalLoading(true);
    getOptions()
      .then((res) => {
        if (res.data) setOptions(res.data.results);
        setLocalLoading(false);
      })
      .catch(() => {
        setOptions([]);
        setLocalLoading(false);
      });
  }, [getOptions]);

  return (
    <FormControl fullWidth className={props.className}>
      <Autocomplete
        id={"idAutoComplete"}
        freeSolo={true}
        value={props?.value}
        options={localLoading ? [] : options.map((el: { number: string; id: number }) => el.number)}
        onChange={(event: OnChangeEvent, value: any) => props.onChange(value)}
        onInputChange={(event: OnChangeEvent, value: any) => props.onChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            margin="normal"
            variant="standard"
            style={{ margin: 0 }}
            // InputProps={{ inputProps: {  
            //   "data-testid": "testAutoCompleteField" 
            // }}}
          />
        )}
      />
    </FormControl>
  );
};
